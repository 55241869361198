//import { persistStore, persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware } from 'redux';
//import { composeWithDevTools } from '@redux-devtools/extension';
import {thunk} from 'redux-thunk';
import appReducer from './reducer';

// const persistConfig = {
//     key: 'root-plus',
//     storage,
// };

//https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
        window.localStorage.removeItem('#root');
        return appReducer({ authReducer: { token: {} } }, action);
    }
    return appReducer(state, action);
};
// const persistedReducer = persistReducer(persistConfig, rootReducer);
const storeObject = createStore(rootReducer, applyMiddleware(thunk));
window.store = storeObject;

export const store = storeObject;

//export const persistor = persistStore(storeObject);
